<script>
import {gsap} from "gsap";
import HamburgerComponent from "@/components/Hamburger.vue";

export default {
    name: 'NavBar',
    data() {
        return {
            isfos: false,
        }
    },
    computed: {
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;
            if (language === 'hu') {
                returnValue = {
                    name: 'Bokody Ákos',
                    visual: 'Képzőművészet',
                    it: 'Informatika',
                    music: 'Zene',
                    contact: 'Kapcsolat',
                    language: 'Switch to English'
                }
            } else {
                returnValue = {
                    name: 'Ákos Bokody',
                    visual: 'Art',
                    it: 'IT',
                    music: 'Music',
                    contact: 'Contact',
                    language: 'Váltás magyar nyelvre'
                }
            }
            return returnValue;
        },
        currentRoute: function () {
            return this.$route.path;
        },

    },
    methods: {
        playVideo(){
            if(!this.isfos){
                document.getElementById('headervideo').play();
            }
            document.getElementById('headervideo').play();
        },
        stopVideo(){
            if(!this.isfos){
                document.getElementById('headervideo').pause();
            }

        },
        changeRoute(route) {
            if(this.$route.path !== route){
                this.$router.push(route);
                this.$store.commit('setNavbarSub', 0);
            }

        },
        changeLanguage(){
            let language__changer = document.getElementById('language__changer')






            gsap.timeline()
                .to(language__changer, {autoAlpha: 1, x: 0, duration: 0.3, zIndex: 500, stagger: 0.1,
                onComplete: () => {
                    let language = this.$store.getters.language;
                    if (language === 'hu') {
                        this.$store.commit('setLanguage', 'en');
                        document.title = 'Ákos Bokody'
                    } else {
                        this.$store.commit('setLanguage', 'hu');
                        document.title = 'Bokody Ákos'
                    }




                }}, '+=0.1')
                .to(language__changer, {autoAlpha: 0, x: 0, duration: 0.3, zIndex: 0, stagger: 0.1}, '+=0.1')
        }
    },
    components: {HamburgerComponent},
    mounted() {

        let p = document.querySelector('.navbar__logo p');

        let entries = document.querySelectorAll('.navbar__items p');


        let platform = navigator.platform;
        this.isfos = platform.toLowerCase().includes('ipad');
        if(!this.isfos && window.innerWidth <= 360){
            this.isfos = true;
        }

        // Detect Safari
        let userAgentString =
            navigator.userAgent;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        let safariAgent = userAgentString.indexOf("Safari") > -1;

        if ((chromeAgent) && (safariAgent)) safariAgent = false;

        if(!this.isfos && safariAgent){
            this.isfos = true;
        }

        let logo;
        if(this.isfos){
            logo = document.getElementById('headervideo2');
        } else {
            logo = document.getElementById('headervideo');
        }

        gsap.timeline()
            .to(logo, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
            .to(p, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(entries, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
    }
}
</script>

<template>
    <div class="navbar">
        <div class="position-relative">
            <video v-if="!isfos" id="headervideo" class="navbar__logo navbar__logo-img" src="../assets/img/ezgif-1-61749e75ca.webm"
                   loop muted @mouseover="playVideo" @mouseout="stopVideo"
                   @click="changeRoute('/')"
            />

            <img v-if="isfos"  id="headervideo2" class="navbar__logo navbar__logo-img" src="../assets/img/bokody.jpg" @click="changeRoute('/')"/>
<!--                <img class="navbar__logo navbar__logo-img navbar__activeimg" src="../assets/img/weblogo.webp" />-->
<!--                <p  class="navbar__logo-p navbar__active">{{navbarNames.name}}</p>-->

<!--                <p :class="{navbar__active: currentRoute === '/'}" >{{ navbarNames.name }}</p>-->

            <div class="navbar__items">
                <p :class="{navbar__active: currentRoute === '/'}" @click="changeRoute('/')">
                    {{ navbarNames.visual }}</p>
<!--                    {{ navbarNames.visual }}</p>-->
                <p class="navbar__items_it" :class="{navbar__active: currentRoute === '/it'}"
                   @click="changeRoute('/it')">{{ navbarNames.it }}</p>
                <p class="navbar__items_music" :class="{navbar__active: currentRoute === '/music'}"
                   @click="changeRoute('/music')">{{ navbarNames.music }}</p>
                <p class="navbar__items_contact" :class="{navbar__active: currentRoute === '/contact'}"
                   @click="changeRoute('/contact')">{{ navbarNames.contact }}</p>
                <p @click="changeLanguage" class="navbar__items_language">{{ navbarNames.language }}</p>
            </div>

            <div id="hamburger">
                <HamburgerComponent />
            </div>
        </div>
    </div>
</template>

<style scoped>
#headervideo2{
    visibility: initial !important;
}
p {
    visibility: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navbar__items_language{
    font-weight: 100;
    margin-top: 40.45px !important;
}

.navbar__logo{
    cursor: pointer;
    visibility: hidden;
    margin-left: 10px;
    margin-top: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navbar {
    width: 100vw;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 30px;
//background-color: aqua;
}

.navbar .position-relative {
    width: inherit;
}

.navbar__items {
    display: flex;
    position: absolute;
    right: 10px;
    top: 0;
    justify-content: space-between;
}

.navbar__items p{
    margin-top: 38px;
}

.navbar__active {
    color: var(--red);
    cursor: initial;
}

p:hover {
    color: var(--red);
}

#hamburger{
    display: none;
}

@media only screen and (max-width: 1023px) {
    .navbar__logo{
        width: 60px;
        margin: 0 !important;
    }

    .navbar__items{
        display: none;
    }

    .navbar{
        background: white !important;
        z-index: 100 !important;
        padding: 10px 10px;
    }

    #hamburger{
        display: block !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1240px)  {
    p{
        font-size: 12pt;
    }

    .navbar__items_language{
        font-size: 10pt;
    }

    .navbar__logo{
        width: 60px;
    }

    .navbar__items{
        width: 52vw;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1366px)  {
    p{
        font-size: 14pt;
    }

    .navbar__items_language{
        font-size: 12pt;
    }

    .navbar__logo{
        width: 80px;
    }

    .navbar__items{
        width: 53vw;
    }
}

@media only screen and (min-width: 1366px)  {
    p{
        font-size: 18pt;
    }

    .navbar__items_language{
        font-size: 16pt;
    }

    .navbar__logo{
        width: 100px;
    }

    .navbar__items{
        width: 54.1vw;
    }
}

@media only screen and (min-width: 3840px) {
    .navbar__logo{
        width: 150px;
        margin-top: -15px !important;
    }
    .navbar{
        padding: 60px 30px;
    }
    .navbar__items{
        width: 55.3vw;
    }
    p{
        font-size: 24pt !important;
    }

    .navbar__items_language{
        font-size: 20pt;
    }
}
</style>