<script>
import Navbar from "@/components/Navbar.vue";
import NavbarSub from "@/components/NavbarSub.vue";
import GalleryComponent from "@/components/Gallery.vue"
import LanguageChange from "@/components/LanguageChange.vue";
import MenuMobile from "@/components/MenuMobile.vue";

export default {
    name: 'App',
    components: {
        Navbar,
        NavbarSub,
        GalleryComponent,
        LanguageChange,
        MenuMobile
    },
    mounted() {
        let browserLanguage = navigator.language || navigator.userLanguage;
        if(!browserLanguage.includes('hu')){
            this.$store.commit('setLanguage', 'en');
        }
    }
}
</script>


<template>
    <div id="app">

        <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath"/>
        </transition>

        <MenuMobile />
        <Navbar/>
        <NavbarSub/>


        <transition name="fade" mode="out-in">
            <div v-if="$store.getters.galleryData !== null" class="gallerycomponent">
                <GalleryComponent/>
            </div>
        </transition>

        <LanguageChange />
    </div>
</template>

<style>
@media only screen and (max-width: 1023px) {
    .gallerycomponent{
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .gallerycomponent{
        display: block;
    }
}
</style>
