<script>
import {gsap} from "gsap";

export default {
    name: 'GalleryComponent',
    data() {
        return {
            currentProject: 0,
        }
    },
    computed:{
        galleryData: function () {
            return this.$store.getters.galleryData;
        },
        navbarSub: function () {
            return this.$store.getters.navbarSub;
        },
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;

            if (language === 'hu') {
                returnValue = {
                    back: 'vissza',
                    prev: 'előző',
                    next: 'következő',
                    swipe: 'További képekért lapozz az ujjaddal!',
                }
            } else {
                returnValue = {
                    back: 'back',
                    prev: 'previous',
                    next: 'next',
                    swipe: 'Swipe for more pictures!',
                }
            }
            return returnValue;
        },
        countString: function (){
          return `${this.currentProject+1} / ${this.galleryData.images.length}`
        }
    },
    methods:{
        showNewElements(newValue) {
            if (newValue === 0) {
                let h1__info = document.querySelector('.row__painting .h1__info');
                let visualarts__statement = document.querySelector('.row__painting .visualarts__statement');
                let h1__projects = document.querySelector('.row__painting .h1__projects');
                let btn__prev = document.querySelector('.row__painting .btn__prev');
                let btn__next = document.querySelector('.row__painting .btn__next');
                let card = document.querySelector('.row__painting .visualarts__project-card');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
            if (newValue === 1) {
                let h1__projects = document.querySelector('.row__video .col__video .h1__projects');
                let btn__prev = document.querySelector('.row__video .btn__prev');
                let btn__next = document.querySelector('.row__video .btn__next');
                let iframe = document.querySelector('.row__video .col__video iframe');

                gsap.timeline()
                    .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(iframe, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
            if (newValue === 2) {
                let h1__projects = document.querySelector('.row__exhibition .h1__projects');
                let btn__prev = document.querySelector('.row__exhibition .btn__prev');
                let btn__next = document.querySelector('.row__exhibition .btn__next');
                let card = document.querySelector('.row__exhibition .visualarts__project-card');

                gsap.timeline()
                    .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
            if (newValue === 3) {
                let h1__info = document.querySelector('.row__cv .h1__cv');
                let visualarts__statement = document.querySelector('.row__cv .visualarts__statement');
                let img = document.querySelector('.row__cv img');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(img, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
        },
        back(){
            let btn_back = document.querySelector('.gallery .btn__back');
            let btn_prev = document.querySelector('.gallery .btn__prev');
            let btn_next = document.querySelector('.gallery .btn__next');
            let img = document.querySelector('.gallery .visualarts__project-card img');
            let title = document.querySelector('.gallery .title')
            let subentries = document.querySelectorAll('.cv__subentry');
            let details = document.querySelector('.gallery .details')
            let h1 = document.querySelector('.gallery h1')
            let p = document.querySelector('.gallery .visualarts__statement')

            let subIndex;
            if(this.$route.path === '/'){
                subIndex = 0;
            }
            if(this.$route.path === '/it'){
                subIndex = 1;
            }
            if(this.$route.path === '/music'){
                subIndex = 2;
            }
            if(this.$route.path === '/contact'){
                subIndex = 3;
            }

            gsap.timeline()
                .to(btn_back, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1})
                .to(btn_prev, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')
                .to(btn_next, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')
                .to(img, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')
                .to(title, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')

                .to(details, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')
                .to(h1, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1}, '-=0.15')
                .to(subentries, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.01}, '-=0.15')
                .to(p, {autoAlpha: 0, x: 0, duration: 0.15, stagger: 0.1, onComplete: () => {
                        this.$store.commit('setGalleryData', null);

                        const isMobile = window.innerWidth <= 1023;
                        if(isMobile){

                            this.$router.push(this.$store.getters.lastRoute);
                            this.$store.commit('setLastRoute', this.$route.path);
                        } else {
                            let logo = document.querySelector('.navbar__logo');
                            let entries = document.querySelectorAll('.navbar__items p');
                            let subentries = document.querySelectorAll('.navbarsub__entries');

                            gsap.timeline()
                                .to(logo, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
                                .to(entries, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                                .to(subentries[subIndex].querySelectorAll('p'), {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')

                            this.showNewElements(this.navbarSub);
                        }

                    }}, '-=0.15')






        },
        nextImage(){
            let image = document.querySelector('.visualarts__project-card img');
            let title = document.querySelector('.visualarts__project-card-details .title');
            let details = document.querySelector('.visualarts__project-card-details .details');
            gsap.timeline()
                .to(image, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1})
                .to(title, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1}, '-=0.15')
                .to(details, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.currentProject++;
                        if(this.galleryData.images.length === this.currentProject){
                            this.currentProject = 0;
                        }
                    }
                }, '-=0.15')
                .to(image, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
                .to(title, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                .to(details, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')


        },
        prevImage(){
            let image = document.querySelector('.visualarts__project-card img');
            let title = document.querySelector('.visualarts__project-card-details .title');
            let details = document.querySelector('.visualarts__project-card-details .details');
            gsap.timeline()
                .to(image, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1})
                .to(title, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1}, '-=0.15')
                .to(details, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.currentProject--;
                        if(this.currentProject === -1){
                            this.currentProject = this.galleryData.images.length - 1;
                        }
                    }
                }, '-=0.15')
                .to(image, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
                .to(title, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                .to(details, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')


        },
    },
    mounted() {
        if(this.$store.getters.galleryData !== null){
            let btn_back = document.querySelector('.gallery .btn__back');
            let btn_prev = document.querySelector('.gallery .btn__prev');
            let btn_next = document.querySelector('.gallery .btn__next');
            let img = document.querySelector('.gallery .visualarts__project-card img');
            let title = document.querySelector('.gallery .title')
            let subentries = document.querySelectorAll('.cv__subentry');
            let imgtitle = document.querySelector('.visualarts__project-card-details .title');
            let details = document.querySelector('.visualarts__project-card-details .details');
            let h1 = document.querySelector('.gallery h1')
            let p = document.querySelector('.gallery .visualarts__statement')
            gsap.timeline()
                .to(btn_back, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1})
                .to(btn_prev, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(btn_next, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(img, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(title, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')

                .to(imgtitle, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(details, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(h1, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
                .to(subentries, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.01}, '-=0.15')
                .to(p, {autoAlpha: 1, x: 0, duration: 0.2, stagger: 0.1}, '-=0.15')
        } else {
            this.$router.push('/')
        }

    }
}
</script>

<template>
  <div class="gallery">
      <div class="btn__back" @click="back">
          <svg viewBox="0 0 15.8 18.16">
              <polygon class="cls-1"
                       points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
          </svg>
          <p class="prev">{{navbarNames.back}}</p>
      </div>

      <p class="imgcountdesktop">{{countString}}</p>


      <div class="btn__prev" @click="prevImage">
          <svg viewBox="0 0 15.8 18.16">
              <polygon class="cls-1"
                       points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
          </svg>
          <p class="prev">{{navbarNames.prev}}</p>
      </div>
      <div class="btn__next" @click="nextImage">
          <p class="next">{{navbarNames.next}}</p>
          <svg viewBox="0 0 15.8 18.16">
              <polygon class="cls-1"
                       points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
          </svg>

      </div>
    <b-row>
        <b-col>
            <div class="visualarts__project">
                <div class="visualarts__project-card" v-touch:swipe.left="nextImage" v-touch:swipe.right="prevImage">
                    <div class="d-flex justify-content-between" >
                        <p class="swipe">{{navbarNames.swipe}}</p>
                        <p class="imgcountmobile">{{countString}}</p>
                    </div>


                    <img alt="img_gallery" :class="{centered: galleryData.images[currentProject].centered}" :src="galleryData.images[currentProject].img"/>
                    <div class="visualarts__project-card-details">
                        <p class="title">
                            {{ galleryData.images[currentProject].title }}
                        </p>

                        <p class="details">{{ galleryData.images[currentProject].details }}
                        </p>
                    </div>

                </div>
            </div>
        </b-col>
        <b-col cols="4">
          <h1>{{ galleryData.title }}</h1>
            <p v-if="galleryData.exhibition_details_date" class="cv__subentry cv__subentry-job">
                <img src="../assets/img/svg/calendar_month_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_calendar"/>
                {{galleryData.exhibition_details_date}}
            </p>
            <p v-if="galleryData.exhibition_details_location" class="cv__subentry cv__subentry-job">
                <img src="../assets/img/svg/location_on_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_location"/>
                {{galleryData.exhibition_details_location}}
            </p>
            <p v-if="galleryData.exhibition_details_technique" class="cv__subentry cv__subentry-job">
                <img src="../assets/img/svg/palette_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_technique"/>
                {{galleryData.exhibition_details_technique}}
            </p>
            <p v-if="galleryData.exhibition_details_medium" class="cv__subentry cv__subentry-job">
                <img src="../assets/img/svg/description_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_medium"/>
                {{galleryData.exhibition_details_medium}}
            </p>
            <p v-if="galleryData.exhibition_details_type" class="cv__subentry">
                <img src="../assets/img/svg/diversity_1_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_exhibition"/>
                {{galleryData.exhibition_details_type}}
            </p>
            <p class="visualarts__statement" v-html="galleryData.text"></p>
        </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.imgcountdesktop{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    top: 28px;
}
.gallery{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 30;
    padding: 40px;
}

.swipe{
    display: none;
}

.imgcountmobile{
    display: none;
}
@media only screen and (max-width: 1023px) {
    .imgcountmobile{
        display: block !important;
    }
    .imgcountdesktop{
        display: none;
    }
    .swipe{
        display: block !important;
    }
    .visualarts__statement {
        overflow: initial !important;
        max-height: initial !important;
        padding-right: 0px !important;
    }
    .gallery{
        left: 0 !important;
        top: 0 !important;
        z-index: 1000 !important;
        padding: 80px 15px 15px !important;
        height: auto !important;
        position: relative !important;
    }

    .gallery *{
        visibility: initial !important;
        transform: initial !important;
    }

    .row {
        width: 100%;
        padding: 0 !important;
        margin-top: 0px !important;
    }

    .col, .col-4{
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        flex: auto !important;
    }

    .col-4{
        order: 1
    }

    .col{
        order: 2;
        margin-top: 30px !important
    }



    .visualarts__project-card-details{
        position: relative !important;
        margin-bottom: 50px !important;
    }

    .visualarts__project-card .title{
        font-size: 14pt !important;
    }

    .visualarts__project-card .details{
        font-size: 12pt !important;
    }


    .visualarts__project-card img {
        max-width: 100% !important;
        max-height: 75vh !important;
        position: relative !important;
        top: 0 !important;
    }

    .prev, .next{
        font-size: 10pt !important;
    }

    svg{
        width: 10px !important;
    }

    .prev{
        margin-left: 5px !important;
    }

    .next{
        margin-right: 5px !important;
    }

    .btn__back {
        left: 15px !important;
        top: 17px;
    }

    .btn__next svg {

        transform: rotate(180deg) !important;
    }

    .btn__prev {
        display: none !important;
        right: 120px !important;
        top: 17px !important;
    }

    .btn__next {
        display: none !important;
        right: 15px !important;
        top: 17px !important;
    }

}



@media only screen and (min-width: 769px) and (max-width: 1023px){

}

@media only screen and (min-width: 2560px) {

    .visualarts__statement{
        font-size: 13pt !important;
        padding-right: 80px;
    }
}

@media only screen and (min-width: 3840px) {
    h1{
        font-size: 40pt !important;
    }
    .cardimgbox{
        height: 94% !important;
    }

    .visualarts__statement{
        font-size: 20pt !important;
        line-height: 40px !important;
        height: 100vh !important;
    }

    .prev, .next, .open{
        font-size: 20pt !important;
    }

    .visualarts__project-card .title{
        font-size: 26pt !important;
        bottom: 5px !important
    }

    .btn__prev, .btn__next{
        top: 20px !important;
        display: block;
    }

    .gallery{
        padding: 80px 40px !important;
    }

    .visualarts__project-card img{
        max-height: 85vh !important;
    }

    .btn__prev{
        right: 230px !important;
    }

    .cv__title{
        font-size: 24pt !important;
    }

    .cv__entry{
        font-size: 20pt !important;
    }

    .cv__subentry{
        font-size: 24pt !important;
    }

    .visualarts__project-card .details{
        font-size: 20pt !important
    }

    .row__cv_scroll{
        height: 73vh !important;
    }

    .visualarts__project-card .open{
        bottom: 5px !important;
    }
}

.centered{
    top: 0 !important;
    bottom: 0 !important;
}

.cv__subentry-job{
    margin-bottom: 0 !important;
}

.cv__subentry img{
    visibility: inherit !important;
    width: 20px !important;
    margin-top: -4px;
}

.cv__subentry {
    visibility: hidden;

    font-size: 13pt;
    color: var(--red);
}

.cv__subentry-jobtitle{
    font-size: 15pt !important;
    color: black !important;
    margin-top: -3px;
}

.visualarts__statement {
    text-align: justify;
    font-size: 12pt;
    color: var(--darkred);
    visibility: hidden;
    overflow-y: scroll;
    margin-top: 30px;
    max-height: 60vh;
    line-height: 25px;
    padding-right: 20px;
    white-space: break-spaces;
}

.row{
    width: 100%;
    //padding-top: 38px;
    margin: 0 !important;
    height: 100%;
    //background-color: #5b0000;

}

h1{
    visibility: hidden;
}

.visualarts__project {
//background-color: green; width: 100%;
    height: 100%;
}

.visualarts__project-card {
    height: 100%;
    position: relative;
    text-align: center;
}

.visualarts__project-card img{
    max-width: 90%;
    max-height: 75vh;
    visibility: hidden;
    //margin-bottom: 30px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 39px;
    bottom: initial;
}

.visualarts__project-card-details{
    position: absolute;
    bottom: -20px;
    left: 0;
}

.visualarts__project-card .title {
    font-size: 20pt;
    text-align: left;
    margin-bottom: 0;
    visibility: hidden;
    color: var(--red);
    transform: translate(-50px, 0);
}

.visualarts__project-card .details {
    font-size: 13pt;
    text-align: left;
    color: var(--darkred);
    //margin-left: 50px;
    margin-top: -3px;
    visibility: hidden;
    transform: translate(-50px, 0);
}

.btn__back {
    display: flex;
    position: absolute;
    left: 40px;
    top: 17px;
    cursor: pointer;
    visibility: hidden;
    z-index: 500;
}

.btn__next svg {

    transform: rotate(180deg);
}

.btn__prev {
    display: flex;
    position: absolute;
    right: 200px;
    top: 18px;
    cursor: pointer;
    visibility: hidden;
}

.btn__next {
    display: flex;
    position: absolute;
    right: 40px;
    top: 17px;
    cursor: pointer;
    visibility: hidden;
}

svg {
    margin-top: -2px;
    width: 15px;
}

.cls-1 {
    fill: black;
}

.prev {

    margin-top: 14px;
    margin-left: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.next {
    margin-top: 15px;
    margin-right: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.col-4{
padding-top: 38px;
    margin-right: 0 !important;
    padding-right: 0 !important;
}
.btn__prev:hover .cls-1, .btn__next:hover .cls-1, .btn__back:hover .cls-1 {
    fill: var(--red)
}

.btn__prev:hover .prev, .btn__back:hover .prev, .btn__next:hover .next {
    color: var(--red)
}
</style>