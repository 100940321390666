<script>
import {gsap} from "gsap";

export default {
    name: 'ITView',
    data() {
        return {
            currentProject: 0,
        }
    },
    computed: {
        language: function (){
            return this.$store.getters.language;
        },
        navbarSub: function () {
            return this.$store.getters.navbarSub;
        },
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;

            if (language === 'hu') {
                returnValue = {
                    projects: 'Projektek',
                    prev: 'előző',
                    next: 'következő',
                    open: 'kinyit',
                    description: 'A képzőművészet mellett mély érdeklődéssel vagyok az informatika iránt is. A középiskola befejezése után a Budapesti Műszaki és Gazdaságtudományi Egyetem mérnökinformatikus szakán tanultam. Az egyetemi évek után számos különböző munkakörben helyezkedtem el, és változatos projekteken dolgoztam. Tapasztalatom kiterjed a backend fejlesztéstől kezdve a frontend fejlesztésen át egészen a szabadúszó webdesign-ig. Külföldi projektekben is részt vettem, ahol az informatikai szakértelmemet és művészi érzékemet egyaránt kamatoztathattam. Ennek eredményeként olyan kompetenciákat szereztem, amelyek lehetővé teszik számomra, hogy a legújabb technológiai trendeket és funkciókat integráljam munkáimba, miközben a letisztult és vonzó design jegyei is jelen vannak. A weboldalaim célja mindig az volt, hogy ne csupán technikailag kiemelkedőek legyenek, hanem a vizuális megjelenésük is tükrözze a modern és esztétikus megközelítést.',
                    cv: 'Önéletrajz',
                    information: 'Informatika',
                    skills: 'Készségek',
                    skills_elems: [
                        {
                            title: 'Fő frontend készségek',
                            text: 'HTML, CSS, Javascript, TypeScript, ES6+\n Vue.js, Svelte, Angular, Next.js\n Jest, Cypress'+
                                '\n Bootstrap, Material Design\n GSAP, SEO\n Wordpress'
                        },
                        {
                            title: 'Alap frontend készségek',
                            text: 'Tailwind, Umbraco',
                        },
                        {
                            title: 'Fő backend készségek',
                            text: 'Node.js, Express, MongoDB',
                        },
                        {
                            title: 'Alap backend készségek',
                            text: 'C, C++, C#, .NET Core, Python',
                        },
                        {
                            title: 'Cloud & CI/CD',
                            text: 'Git, Docker, Nginx, CloudFlare, DigitalOcean',
                        },
                        {
                            title: 'Grafikai készségek',
                            text: 'Adobe Photoshop, Adobe Illustrator' + '\n Adobe InDesign, Adobe Premiere Pro',
                        }

                    ],
                    education: 'Tanulmányok',
                    education_elems: [
                        {
                            title: 'Budapesti Műszaki és \nGazdaságtudományi Egyetem',
                            text: 'mérnökinformatikus szak, 2016—2019',
                        }
                    ],
                    experience: 'Tapasztalat',
                    experience_elems: [
                      {
                        title: 'Full stack fejlesztő',
                        company: 'Quantum Digitális Diákszövetkezet',
                        date: '2024/06—jelenleg',
                        location: 'Budapest, Magyarország',
                      },
                        {
                            title: 'Mid-level frontend fejlesztő',
                            company: 'Work Pilots',
                            date: '2020/01—jelenleg',
                            location: 'Espoo, Finnország',
                        },
                        // {
                        //     title: 'IT projekt menedzser',
                        //     company: 'CatharSys Software',
                        //     date: '2024/01 - jelenleg',
                        //     location: 'Budapest, Magyarország',
                        // },
                        {
                            title: 'Full stack fejlesztő',
                            company: 'Y2K.design',
                            date: '2019/11—2024/06',
                            location: 'Budapest, Magyarország',
                        },
                        {
                            title: 'Mid-level full stack fejlesztő',
                            company: 'FreshConstruct',
                            date: '2021/02—2021/07',
                            location: 'London, Egyesült Királyság',
                        },
                        {
                            title: 'Junior frontend fejlesztő',
                            company: 'Athletic Vision',
                            date: '2020/06—2020/10',
                            location: 'Budapest, Magyarország',
                        },

                        {
                            title: 'Junior full stack fejlesztő',
                            company: 'Candid ITS',
                            date: '2019/05—2019/09',
                            location: 'Budapest, Magyarország',
                        },
                        {
                            title: 'Junior rendszergazda',
                            company: 'Dorsum',
                            date: '2018/06—2018/09',
                            location: 'Budapest, Magyarország',
                        },
                        {
                            title: 'Junior szervíztechnikus',
                            company: 'Sagemcom',
                            date: '2017/06—2017/09',
                            location: 'Budapest, Magyarország',
                        },
                    ],
                    portfolio_elems: [
                        {
                            title: 'Málna Manufaktúra',
                        },
                        {
                            title: 'Privát raktározó',
                        },
                        {
                            title: 'WorkPilots',
                        },
                        {
                            title: 'ASAN Budapest',
                        },
                        {
                            title: 'Zosha Works',
                        },
                        {
                            title: 'Fehérvári Viziterv Kft.',
                        },
                        {
                            title: 'Vándornapló',
                        },
                        {
                            title: 'CatharSys Software',
                        },
                    ],

                }

            } else{
                returnValue = {
                    projects: 'Projects',
                    prev: 'previous',
                    next: 'next',
                    open: 'open',
                    description: 'Besides arts, I have a deep interest in IT. After finishing high school, I studied computer engineering at the Budapest University of Technology and Economics. After my university years, I worked in a variety of jobs and projects. My experience ranges from backend development to frontend development and freelance web design. I have also been involved in projects abroad, where I have been able to combine my IT expertise with an artistic flair. As a result, I have acquired competencies that allow me to integrate the latest technological trends and features into my work while maintaining a clean and attractive design. The goal of my websites has always been to not only be technically outstanding but also to reflect a modern and aesthetic approach to their visual appearance.',
                    cv: 'CV',
                    information: 'IT',
                    skills: 'Skills',
                    skills_elems: [
                        {
                            title: 'Main frontend skills',
                            text: 'HTML, CSS, Javascript, TypeScript, ES6+\n Vue.js, Svelte, Angular, Next.js\n Jest, Cypress'+
                                '\n Bootstrap, Material Design\n GSAP, SEO\n Wordpress'
                        },
                        {
                            title: 'Basic frontend skills',
                            text: 'TypeScript, Tailwind, Umbraco',
                        },
                        {
                            title: 'Main backend skills',
                            text: 'Node.js, Express, MongoDB',
                        },
                        {
                            title: 'Basic backend skills',
                            text: 'C, C++, C#, .NET Core, Python',
                        },
                        {
                            title: 'Cloud & CI/CD',
                            text: 'Git, Docker, Nginx, CloudFlare, DigitalOcean',
                        },
                        {
                            title: 'Graphic design skills',
                            text: 'Adobe Photoshop, Adobe Illustrator' + '\n Adobe InDesign, Adobe Premiere Pro',
                        }

                    ],
                    education: 'Education',
                    education_elems: [
                        {
                            title: 'Budapest University of Technology and Economics',
                            text: 'computer engineer, 2016—2019',
                        }
                    ],
                    experience: 'Experience',
                    experience_elems: [
                      {
                        title: 'Full stack developer',
                        company: 'Quantum Digitális Diákszövetkezet',
                        date: '2024/06—currently',
                        location: 'Budapest, Hungary',
                      },
                        {
                            title: 'Mid-level frontend developer',
                            company: 'Work Pilots',
                            date: '2020/01—currently',
                            location: 'Espoo, Finland',
                        },
                        // {
                        //     title: 'IT project manager',
                        //     company: 'CatharSys Software',
                        //     date: '2024/01 - currently',
                        //     location: 'Budapest, Hungary',
                        // },
                        {
                            title: 'Full stack developer',
                            company: 'Y2K.design',
                            date: '2019/11—2024/06',
                            location: 'Budapest, Hungary',
                        },
                        {
                            title: 'Mid-level full stack developer',
                            company: 'FreshConstruct',
                            date: '2021/02—2021/07',
                            location: 'London, United Kingdom',
                        },
                        {
                            title: 'Junior frontend developer',
                            company: 'Athletic Vision',
                            date: '2020/06—2020/10',
                            location: 'Budapest, Hungary',
                        },

                        {
                            title: 'Junior full stack developer',
                            company: 'Candid ITS',
                            date: '2019/05—2019/09',
                            location: 'Budapest, Hungary',
                        },
                        {
                            title: 'Junior system administrator',
                            company: 'Dorsum',
                            date: '2018/06—2018/09',
                            location: 'Budapest, Hungary',
                        },
                        {
                            title: 'Junior service technician',
                            company: 'Sagemcom',
                            date: '2017/06—2017/09',
                            location: 'Budapest, Hungary',
                        },
                    ],
                    portfolio_elems: [
                        {
                            title: 'Málna Manufaktúra',
                        },
                        {
                            title: 'Private storage manager',
                        },
                        {
                            title: 'WorkPilots',
                        },
                        {
                            title: 'ASAN Budapest',
                        },
                        {
                            title: 'Zosha Works',
                        },
                        {
                            title: 'Fehérvári Viziterv Kft.',
                        },
                        {
                            title: 'Vándornapló',
                        },
                        {
                            title: 'CatharSys Software',
                        },
                    ],
                }
            }
            return returnValue;
        },
    },
    watch: {
        'navbarSub'(newValue, oldValue) {
            this.currentProject = 0;
            this.hideOldElements(oldValue);
            this.showNewElements(newValue);
        },
        'language'() {
            if(this.navbarSub === 0){
                let title = document.querySelector('.row__painting .visualarts__project-card .title');
                title.innerText = this.navbarNames.portfolio_elems[this.currentProject].title;
            }

        }
    },
    components: {},
    methods: {
        hideOldElements(oldValue) {
            if (oldValue === 0) {
                let h1__info = document.querySelector('.row__painting .h1__info');
                let visualarts__statement = document.querySelector('.row__painting .visualarts__statement');
                let h1__projects = document.querySelector('.row__painting .h1__projects');
                let btn__prev = document.querySelector('.row__painting .btn__prev');
                let btn__next = document.querySelector('.row__painting .btn__next');
                let card = document.querySelector('.row__painting .visualarts__project-card');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1})
                    .to(visualarts__statement, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(h1__projects, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__prev, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__next, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(card, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
            if (oldValue === 1) {
                let h1__info = document.querySelector('.row__cv .h1__cv');
                let visualarts__statement = document.querySelectorAll('.row__cv p');
                let img = document.querySelector('.cv__image');
                let cv__skills = document.querySelector('.row__cv_scroll');
                let cv__experience_tab = document.querySelector('.cv__experience_tab');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1}, )
                    .to(visualarts__statement, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(cv__skills, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(cv__experience_tab, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(img, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
        },
        showNewElements(newValue) {
            if (newValue === 0) {
                let h1__info = document.querySelector('.row__painting .h1__info');
                let visualarts__statement = document.querySelector('.row__painting .visualarts__statement');
                let h1__projects = document.querySelector('.row__painting .h1__projects');
                let btn__prev = document.querySelector('.row__painting .btn__prev');
                let btn__next = document.querySelector('.row__painting .btn__next');
                let card = document.querySelector('.row__painting .visualarts__project-card');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
                    .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
            if (newValue === 1) {
                let h1__info = document.querySelector('.row__cv .h1__cv');
                let visualarts__statement = document.querySelectorAll('.row__cv p');
                let img = document.querySelector('.cv__image');
                let cv__skills = document.querySelector('.row__cv_scroll');
                let cv__experience_tab = document.querySelector('.cv__experience_tab');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=1')
                    .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(cv__skills, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(cv__experience_tab, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(img, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
        },
        changeCardDetails() {
            let title = document.querySelector('.row__painting .visualarts__project-card .title');
            let img = document.querySelector('.row__painting .visualarts__project-card-img');

            title.innerText = this.navbarNames.portfolio_elems[this.currentProject].title;
            if (this.currentProject === 0) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/malna.webp")})`
            }
            if (this.currentProject === 1) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/malna2.webp")})`
            }
            if (this.currentProject === 2) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/workpilots.webp")})`
            }
            if (this.currentProject === 3) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/asan.webp")})`
            }
            if (this.currentProject === 4) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/zoshaworks.webp")})`
            }
            if (this.currentProject === 5) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/fviziterv.webp")})`
            }
            if (this.currentProject === 6) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/vandornaplo.webp")})`
            }
            if (this.currentProject === 7) {
                img.style.backgroundImage = `url(${require("@/assets/img/content/it/catharsys.webp")})`
            }
            // if (this.currentProject === 4) {
            //     title.innerText = 'SzínKör'
            //     img.style.backgroundImage = `url(${require("@/assets/img/content/visualarts/projects/04_formanyelvkereses/bokody_akos_40.webp")})`
            // }
            // if (this.currentProject === 5) {
            //     title.innerText = 'Vándornapló'
            //     img.style.backgroundImage = `url(${require("@/assets/img/content/visualarts/projects/02_elsoabsztrakciok_szines/ketkek.webp")})`
            // }
            // if (this.currentProject === 4) {
            //     title.innerText = 'Fehérvári VIZITERV Kft.'
            //     img.style.backgroundImage = `url(${require("@/assets/img/content/it/fviziterv.webp")})`
            // }
        },
        nextProjectPaintings(){
            this.currentProject++;
            if (this.currentProject === 8) {
                this.currentProject = 0;
            }


            let card = document.querySelector('.row__painting .visualarts__project-card');
            gsap.timeline()
                .to(card, {
                    autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.changeCardDetails();
                    }
                },)
                .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
        },

        prevProjectPaintings(){
            this.currentProject--;
            if (this.currentProject === -1) {
                this.currentProject = 7;
            }


            let card = document.querySelector('.row__painting .visualarts__project-card');
            gsap.timeline()
                .to(card, {
                    autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.changeCardDetails();
                    }
                },)
                .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.3')
        },
        nextProject() {
            if(this.navbarSub === 0){
                this.nextProjectPaintings();
            }
        },
        prevProject() {
            if(this.navbarSub === 0){
                this.prevProjectPaintings();
            }
        },
        openGallery(){

            if(this.currentProject === 0){
                window.open("https://malnamanufaktura.hu", "_blank");
            }
            if(this.currentProject === 1){
                window.open("https://www.behance.net/gallery/152601611/MALNA-MANUFAKTURA-storage-manager-UXUI", "_blank");
            }
            if(this.currentProject === 2){
                window.open("https://web.workpilots.com/", "_blank");
            }
            if(this.currentProject === 3){
                window.open("https://asanbudapest.com", "_blank");
            }
            if(this.currentProject === 4){
                window.open("https://zoshaworks.com", "_blank");
            }
            if(this.currentProject === 5){
                window.open("https://fehervariviziterv.hu", "_blank");
            }
            if(this.currentProject === 6){
                window.open("https://vandornaplo.hu", "_blank");
            }
            if(this.currentProject === 7){
                window.open("https://catharsyssoftware.com/", "_blank");
            }

        },
    },
    mounted() {


        let h1__info = document.querySelector('.h1__info');
        let visualarts__statement = document.querySelector('.visualarts__statement');
        let h1__projects = document.querySelector('.h1__projects');
        let btn__prev = document.querySelector('.btn__prev');
        let btn__next = document.querySelector('.btn__next');
        let card = document.querySelector('.visualarts__project-card');

        gsap.timeline()
            .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.1')
            .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
    }
}
</script>

<template>
    <div class="visualarts">
        <b-row class="row__painting" :class="{row_active: navbarSub === 0, row_inactive: navbarSub !== 0}">
            <b-col cols="4">
                <h1 class="h1__info">{{navbarNames.information}}</h1>
                <p class="visualarts__statement">{{navbarNames.description}}</p>
            </b-col>
            <b-col class="position-relative">
                <h1 class="h1__projects">{{navbarNames.projects}}</h1>

                <div class="btn__prev" @click="prevProject">
                    <svg viewBox="0 0 15.8 18.16">
                        <polygon class="cls-1"
                                 points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
                    </svg>
                    <p class="prev">{{navbarNames.prev}}</p>
                </div>
                <div class="btn__next" @click="nextProject">
                    <p class="next">{{navbarNames.next}}</p>
                    <svg viewBox="0 0 15.8 18.16">
                        <polygon class="cls-1"
                                 points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
                    </svg>

                </div>
                <div class="visualarts__project">
                    <div class="visualarts__project-card" @click="openGallery" >
                        <div class="cardimgbox">
                            <div class="visualarts__project-card-img">
                                <!--                            <img src="../assets/img/09_bardo/11.webp" />-->
                            </div>
                        </div>

                        <p class="title">Málna Manufaktúra</p>
                        <p class="open">
                            {{navbarNames.open}}
                            <svg viewBox="0 0 15.8 18.16">
                                <polygon class="cls-1"
                                         points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
                            </svg>
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row class="row__cv" :class="{row_active: navbarSub === 1, row_inactive: navbarSub !== 1}">
            <b-col cols="4" class="position-relative">
                <h1 class="h1__cv">{{navbarNames.cv}}</h1>

                <div class="row__cv_scroll">
                    <p class="cv__title cv_margin_top">
                        <img src="../assets/img/svg/handyman_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_experience"/>
                        {{navbarNames.experience}}
                    </p>


                    <div class="cv__experience_tab">
                        <div v-for="(item, index) in navbarNames.experience_elems" :key="`experience_left-${index}`">
                            <p class="cv__entry">
                                {{item.title}}
                            </p>
                            <p class="cv__subentry cv__subentry-job cv__subentry-jobtitle">
                                {{item.company}}
                            </p>
                            <p class="cv__subentry cv__subentry-job">
                                <img src="../assets/img/svg/calendar_month_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_data"/>
                                {{item.date}}
                            </p>
                            <p class="cv__subentry">
                                <img src="../assets/img/svg/location_on_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_location"/>
                                {{item.location}}
                            </p>
                        </div>
                    </div>
                    <p class="cv__title">
                        <img src="../assets/img/svg/extension_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_skills"/>
                        {{navbarNames.skills}}
                    </p>

                    <div class="cv__skills">
                        <div v-for="(item, index) in navbarNames.skills_elems" :key="`skills-${index}`">
                            <p class="cv__entry">
                                {{item.title}}
                            </p>
                            <p class="cv__subentry" style="white-space: pre;">
                                {{item.text}}
                            </p>
                        </div>
                    </div>



                    <p class="cv__title">
                        <img src="../assets/img/svg/school_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_education"/>
                        {{navbarNames.education}}
                    </p>
                    <div class="cv__skills" v-for="(item, index) in navbarNames.education_elems" :key="`education-${index}`">
                        <p class="cv__entry" style="white-space: pre-line;">
                            {{item.title}}
                        </p>
                        <p class="cv__subentry">
                            {{item.text}}
                        </p>
                    </div>

                </div>

            </b-col>
            <b-col style="text-align: center">
                <img alt="img_hacker" class="cv__image" src="../assets/img/content/it/hacker.webp"/>





            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
.cv__image{
    height: 78vh;
    width: auto !important;
    max-width: 100%;
}
.visualarts__statement {
    font-size: 12pt;
    line-height: 25px;
    color: var(--darkred);
    visibility: hidden;
    width: 30vw;
    padding-right: 30px;
    overflow-y: scroll;
}

.visualarts__project-card .title {
    position: absolute;
    left: 0;
    transition: ease all 0.3s;
}

.visualarts__project-card .open {
    position: absolute;
    right: 0;
    transition: ease all 0.3s;
}

.cv__title {
    margin-top: 30px;
    color: black;
}

.cv__entry {
    color: var(--red);
    margin-bottom: 0;
    margin-left: 40px;
}

.cv__subentry {
    color: var(--darkred);
    margin-left: 40px;
}

.cv__skills{
    //visibility: hidden;
    //overflow-y: scroll;
    width: 33vw;
    padding-right: 30px;
}

.cv__experience_tab{
    //visibility: hidden;
    //overflow-y: scroll;
    width: 22vw;
}

.row__cv_scroll{
    visibility: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 71vh;
}

@media only screen and (max-width: 1023px) {

    .cv__image{
        width: 100% !important;
        height: auto !important;
        max-height: initial !important;
    }

    .row__cv_scroll{
        visibility: initial !important;
        overflow-y: initial !important;
        overflow-x: initial !important;
        height: initial !important;
    }
    .visualarts {
        position: relative;
        margin-top: 100px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .row{
        position: relative !important;
        height: initial !important;
    }

    .col-4, .col{
        width: 100vw;
        margin: 0 !important;
        padding: 0 !important;
        flex: 0 0 auto;
    }

    .visualarts__statement{
        width: fit-content;
        overflow: hidden;
        padding: 0 !important;
        hyphens: auto;
    }

    .cardimgbox{
        height: 400px !important;
    }

    .prev, .next{
        font-size: 10pt !important;
    }

    svg{
        width: 10px !important;
    }

    .prev{
        margin-left: 5px !important;
    }

    .next{
        margin-right: 5px !important;
    }

    .btn__prev{
        top: -6px !important;
        right: 100px !important;
    }
    .btn__next{
        top: -7.3px !important;
    }

    .visualarts__project-card .title{
        font-size: 14pt !important;
        margin-top: 5px !important;
    }

    .open{
        font-size: 10pt;
        margin-top: 8px
    }

    .open svg {
        margin-top: -2.7px;
        margin-left: 3px;
    }

    .h1__projects, .col__video iframe, .visualarts__project-card, .h1__cv, .cv__title, .cv__entry, .cv__subentry, .btn__prev, .btn__next, .row__cv img{
        visibility: visible !important;
        transform: initial !important;
    }

    .cv__skills, .cv__experience_tab{
        width: 100% !important;
    }

    .row.row__cv {
        margin-top: 60px !important;
    }

    .cv__title{
        font-size: 14pt;
    }

    .cv__entry, .cv__subentry{
        margin: 0 !important;
    }

    .cv__subentry{
        white-space: initial !important;
        margin-bottom: 10px !important;
    }
    .cv__skills, .cv__experience_tab{
        padding-left: 39px !important;
        padding-right: 39px !important;
    }

    .cv__subentry.cv__subentry-jobtitle{
        font-size: 12pt !important;
    }

    #collast img{
        margin-top: 30px !important;
    }

    .col:last-child{
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px){

}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    .btn__prev, .btn__next{
        top: -16px !important;
        display: block;
    }

    .row__cv_scroll{
        overflow-x: scroll !important;
    }

    h1{
        font-size: 1.2rem;
    }

    .visualarts__statement{
        max-height: 50vh;
        font-size: 10pt;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 16pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }

    .cv__title{
        font-size: 14pt;
    }

    .cv__entry{
        font-size: 12pt;
    }

    .cv__subentry{
        font-size: 10pt;
    }

    .cv__skills{
        //max-height: 48vh;
    }

    .cv__experience_tab{
        //max-height: 63vh;
    }
}


@media only screen and (min-width: 1366px) and (max-width: 1400px) {
    .visualarts__statement{
        overflow-y: scroll !important;
        max-height: 70vh;
    }

    .visualarts__project-card{
        height: 95% !important;
    }

    .cardimgbox{
        height: 85% !important;
    }

    .cv__exhibitions{
        max-height: initial !important;
    }

    .row__cv_scroll{
        overflow-x: scroll !important;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    .row__cv_scroll{
        overflow-x: scroll !important;
    }

    h1{
        font-size: 2rem;
    }

    .visualarts__statement{
        max-height: 50vh;
    }

    .visualarts__project-card .title {
        bottom: 8px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 15px;
    }

    .cv__title{
        font-size: 18pt;
    }

    .cv__entry{
        font-size: 15pt;
    }

    .cv__subentry{
        font-size: 12pt;
    }

    .cv__skills{
        //max-height: 41vh;
    }

    .cv__experience_tab{
        //max-height: 61.5vh;
    }

    .cv_margin_top{
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }
    h1{
        font-size: 2.5rem;
    }

    .visualarts__statement{
        overflow: initial;
        //max-height: 70vh;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }

    .cv__title{
        font-size: 18pt;
    }

    .cv__entry{
        font-size: 15pt;
    }

    .cv__subentry{
        font-size: 12pt;
    }

    .cv__skills{
        //max-height: 49vh;
    }

    .cv__experience_tab{
        //max-height: 63vh;
    }

    .cv_margin_top{
        margin-top: 0px;
    }
}



@media only screen and (min-width: 2560px) {
    .cardimgbox{
        height: 92% !important;
    }

    .visualarts__statement{
        font-size: 13pt !important;
        padding-right: 80px;
    }
}

@media only screen and (min-width: 3840px) {
    h1{
        font-size: 40pt !important;
    }
    .cardimgbox{
        height: 94% !important;
    }

    .visualarts__statement{
        font-size: 20pt !important;
        padding-right: 120px;
        line-height: 40px;
    }

    .prev, .next, .open{
        font-size: 20pt !important;
    }

    .visualarts__project-card .title{
        font-size: 26pt !important;
        bottom: 5px !important
    }

    .btn__prev, .btn__next{
        top: 0px !important;
        display: block;
    }

    .btn__prev{
        right: 230px !important;
    }
    .cv__title{
        font-size: 24pt !important;
    }

    .cv__entry{
        font-size: 20pt !important;
    }

    .cv__subentry{
        font-size: 16pt !important;
    }

    .row__cv_scroll{
        height: 73vh !important;
    }

    .visualarts__project-card .open{
        bottom: 5px !important;
    }
}

.cv_margin{
    margin-top: 130px;
}
.open .cls-1{
    transition: ease all 0.3s;
}

.cv__title img{
    width: 30px !important;
    visibility: inherit !important;
    margin-right: 3px;
    margin-top: -3px;
}

.row_active{
    z-index: 10;
}

.row_inactive{
    z-index: 1;
}

.cv__subentry-job{
    margin-bottom: 0 !important;
}

.h1__info, .cv__entry, .h1__cv, .cv__title, .cv__subentry {
    visibility: hidden;
    transform: translate(-50px, 0);
}

.cv__subentry img{
    visibility: inherit !important;
    width: 20px !important;
    margin-top: -4px;
}
.h1__projects {
    visibility: hidden;
}

.btn__prev {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 7px;
    right: 160px;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__next {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 6px;
    right: 0;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__prev:hover .prev, .btn__next:hover .next {
    color: var(--red)
}

.btn__prev:hover .cls-1, .btn__next:hover .cls-1 {
    fill: var(--red)
}

.btn__next svg, .open svg {
    transform: rotate(180deg);
}

.open svg{
    margin-top: -3.1px;
    margin-left: 10px;
}

.row:nth-child(1){
}

svg {
    margin-top: -2px;
    width: 15px;
}

.cls-1 {
    fill: black;
}

.next {
    margin-top: 15px;
    margin-right: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.prev {

    margin-top: 14px;
    margin-left: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.visualarts__project {
//background-color: green; width: 100%;
    height: 100%;
}

.cv__subentry-jobtitle{
    font-size: 15pt !important;
    color: black !important;
    margin-top: -3px;
}


.col__video {
    margin-left: 0 !important;
}

.col__video iframe {
    width: 100%;
    height: 95%;
    visibility: hidden;
}

.row {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    height: 75vh;
    position: absolute;
    left: 0;
    top: 0;
}

.col-4 {
//padding: 20px 30px; text-align: justify;
}

h1 {
    margin-bottom: 20px;
}

.col {
//padding-left: 20px; //padding-top: 30px; margin-left: 40px;
    margin-right: 0 !important;
    padding-right: 0 !important;
//background-color: green;
}

.visualarts__project-card {
    visibility: hidden;
    margin-top: 15px;
//background-color: green; width: 100%;
    height: 100%;
    position: relative;
}

.visualarts__project-card:hover {
    cursor: pointer;
}

.visualarts__project-card:hover .visualarts__project-card-img {
    transform: scale(1.1);
    //filter: sepia(100%) hue-rotate(310deg) saturate(400%) contrast(120%);
}

.visualarts__project-card:hover .title, .visualarts__project-card:hover .open {
    color: var(--red);
}

.visualarts__project-card:hover .cls-1{
    fill: var(--red)
}


.cardimgbox{
    height: 88%;
    width: 100%;
    overflow: hidden;
}

.visualarts__project-card-img {
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/content/it/malna.webp");
    background-size: cover;
    background-position: center;
    transition: ease all 0.3s;
}


.visualarts__project-card img {
    width: 100%;
}

.row__cv img{
    width: 100%;
    visibility: hidden;
}
h2 {
    margin-top: 10px;
}
</style>