<script>
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {gsap} from "gsap";
export default {
    computed: {
        language: function (){
          return this.$store.getters.language;
        },
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;
            if (language === 'hu') {
                returnValue = {
                    name: 'Bokody Ákos',
                    visual: 'Képzőművészet',
                    it: 'Informatika',
                    music: 'Zene',
                    contact: 'Kapcsolat',
                    language: 'Switch to English'
                }
            } else {
                returnValue = {
                    name: 'Ákos Bokody',
                    visual: 'Art',
                    it: 'IT',
                    music: 'Music',
                    contact: 'Contact',
                    language: 'Váltás magyar nyelvre'
                }
            }
            return returnValue;
        },
        currentRoute: function () {
            return this.$route.path;
        },

    },
    methods: {
        changeRoute(route) {
            if(this.$route.path !== route){
                this.$router.push(route);
                this.$store.commit('setNavbarSub', 0);
                this.$store.commit('toggleHamburger');
            }

        },
        changeLanguage() {
            let language = this.$store.getters.language;
            if (language === 'hu') {
                this.$store.commit('setLanguage', 'en');
                document.title = 'Ákos Bokody'
            } else {
                this.$store.commit('setLanguage', 'hu');
                document.title = 'Bokody Ákos'
            }
        }
    },
    mounted() {
        gsap.registerPlugin(ScrollToPlugin);
    }
}
</script>

<template>
    <div class="menu__mobile">
        <transition name="fade" mode="out-in">
        <div class="menu__mobile-wrapper" v-if="language === 'hu'" key="langhu">
            <p :class="{navbar__active: currentRoute === '/'}" @click="changeRoute('/')">
                {{ navbarNames.visual }}</p>
            <p class="navbar__items_it" :class="{navbar__active: currentRoute === '/it'}"
               @click="changeRoute('/it')">{{ navbarNames.it }}</p>
            <p class="navbar__items_music" :class="{navbar__active: currentRoute === '/music'}"
               @click="changeRoute('/music')">{{ navbarNames.music }}</p>
            <p class="navbar__items_contact" :class="{navbar__active: currentRoute === '/contact'}"
               @click="changeRoute('/contact')">{{ navbarNames.contact }}</p>
            <p @click="changeLanguage" class="navbar__items_language">{{ navbarNames.language }}</p>
        </div>

        <div v-else class="menu__mobile-wrapper" key="langen">
            <p :class="{navbar__active: currentRoute === '/'}" @click="changeRoute('/')">
                {{ navbarNames.visual }}</p>
            <p class="navbar__items_it" :class="{navbar__active: currentRoute === '/it'}"
               @click="changeRoute('/it')">{{ navbarNames.it }}</p>
            <p class="navbar__items_music" :class="{navbar__active: currentRoute === '/music'}"
               @click="changeRoute('/music')">{{ navbarNames.music }}</p>
            <p class="navbar__items_contact" :class="{navbar__active: currentRoute === '/contact'}"
               @click="changeRoute('/contact')">{{ navbarNames.contact }}</p>
            <p @click="changeLanguage" class="navbar__items_language">{{ navbarNames.language }}</p>
        </div>
        </transition>
    </div>
</template>

<style scoped>
.navbar__active {
    color: var(--red) !important;
    cursor: initial;
}


@media (min-width: 0px) and (max-width: 1023px){
    .menu__mobile{
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: white;
        visibility: hidden;
    }

    .menu__mobile p{
        color: black;
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 40px;
    }

    .menu__mobile-wrapper{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
    .navbar__items_language{
        font-weight: 100;
    }
}

@media (min-width: 1024px){
    .menu__mobile{
        display: none;
    }
}
</style>