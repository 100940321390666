import Vue from 'vue'
import VueRouter from 'vue-router'
import VisualArtsView from "@/views/VisualArtsView.vue";
import ITView from "@/views/ITView.vue";
import MusicView from "@/views/MusicView.vue";
import ContactView from "@/views/ContactView.vue";
import Gallery from "@/components/Gallery.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: VisualArtsView
  },
  {
    path: '/it',
    name: 'it',
    component: ITView
  },
  {
    path: '/music',
    name: 'music',
    component: MusicView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router

