import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'hu',
    navbarSub: 0,
    galleryData: null,
    hamburger: false,
    lastRoute: '',
  },
  getters: {
    language: state => {
      return state.language;
    },
    navbarSub: state => {
      return state.navbarSub;
    },
    galleryData: state => {
      return state.galleryData;
    },
    hamburger: state => {
      return state.hamburger;
    },
    lastRoute: state => {
      return state.lastRoute;
    }
  },
  mutations: {
    setLanguage: function (state, newValue){
      state.language = newValue;
    },
    setNavbarSub: function (state, newValue) {
      state.navbarSub = newValue;
    },
    setGalleryData: function (state, newValue) {
      state.galleryData = newValue;
    },
    toggleHamburger: function (state){
      state.hamburger = !state.hamburger;
    },
    setLastRoute: function (state, newValue){
      state.lastRoute = newValue;
    },
  },
  actions: {
  },
  modules: {
  }
})
