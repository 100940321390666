<script>
import {gsap} from "gsap";

export default {
    name: 'MusicView',
    data() {
        return {
            currentProject: 0,
        }
    },
    computed: {
        navbarSub: function () {
            return this.$store.getters.navbarSub;
        },
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;

            if (language === 'hu') {
                returnValue = {
                    statement: 'A zenehallgatás fiatal korom óta mélyen inspirál. Elsőként a hip-hop szubkultúra fogott meg, majd vidékről Budapestre költözve mélyebben elmerültem a tört elektronikus műfajokban, mint például a dubstep, halftime és drum and bass. Ahogy fokozatosan felfedeztem Budapest szórakozóhelyeit és nyári zenei fesztiválokon vadultam, a techno és psy műfajok is gazdagították zenei palettámat. DJ-szettjeim összeállításakor mindig is kísérletező, eklektikus megközelítés jellemzett. Az új hangzások felfedezése számomra nélkülözhetetlen. Zenélés közben az ismeretlent érintve keresem a határaimat. \n' +
                        'A szettjeimben élőben kutatom egy karcosan erős, elmélyülően atmoszférikus, végtelenbe nyúló, törzsiesen lüktető, húzó, dinamikus, pulzáló érzet kereteit.\n' +
                        '2022-ben barátaimmal közösen megalapítottuk a Hidden Crackling nevű DJ kollektívát, melynek célja az általam is képviselt hangzásvilághoz tartozó zenei rendezvények megvalósítása. \n' +
                        'Az alábbi linkre kattintva megtekinthető az eddigi munkásságunk.\n',
                    records: 'Felvételek',
                    prev: 'előző',
                    next: 'következő',
                    information: 'Zene',
                }
            } else {
                returnValue = {
                    statement: 'Listening to music has inspired me deeply since I was young. I first got hooked on the hip-hop subculture, and then moving from the countryside to Budapest, I got more immersed in electronic genres such as dubstep, halftime, and drum and bass. As I gradually explored the nightclubs of Budapest and went wild at summer music festivals, techno and psy genres enriched my musical palette. I\'ve always taken an experimental, eclectic approach when putting together my DJ sets. Exploring new sounds is essential for me. While making music, I push my boundaries by touching the unknown. \n' +
                        'In my sets, my aim is to explore the web of scratchy, powerful, immersively atmospheric, infinitely stretching, tribally pulsating, pulling, dynamic and pulsating sensations on the spot, “live”.\n' +
                        'In 2022, my friends and I co-founded a DJ collective called Hidden Crackling, dedicated to creating musical events in the sonic world I represent. \n' +
                        'Click on the link below to see our work to date.\n',
                    records: 'Records',
                    prev: 'previous',
                    next: 'next',
                    information: 'Music',
                }
            }
            return returnValue;
        }
    },
    watch: {},
    components: {},
    methods: {
        nextProjectVideo() {
            this.currentProject++;
            if (this.currentProject >= 5) {
                this.currentProject = 0;
            }


            let iframe = document.querySelector('iframe');
            gsap.timeline()
                .to(iframe, {
                    autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.changeVideoDetails();
                    }
                },)
                .to(iframe, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.5')
        },
        changeVideoDetails() {
            let iframe = document.querySelector('iframe');
            if (this.currentProject === 0) {
                iframe.src = "https://www.youtube.com/embed/KbGpMiDsvq4?si=Bh1K65_XwIAcB-QT";
            }
            if (this.currentProject === 1) {
                iframe.src = "https://www.youtube.com/embed/om108Fo-4gU?si=Y8UpjlC-vP2zcBe1";
            }
            if (this.currentProject === 2) {
                iframe.src = "https://www.youtube.com/embed/3SjLk0Ydslc?si=aKx0MqlKef1EPtPa";
            }
            if (this.currentProject === 3) {
                iframe.src = "https://www.youtube.com/embed/QN0Hnyxy-3Y?si=iJ1E72idrkYhe_Tq";
            }
            if (this.currentProject === 4) {
                iframe.src = "https://www.youtube.com/embed/YnOtRxx0IEw?si=YIeviAapMt8SS4tg";
            }

        },
        prevProjectVideo() {
            this.currentProject--;
            if (this.currentProject === -1) {
                this.currentProject = 4;
            }


            let iframe = document.querySelector('iframe');
            gsap.timeline()
                .to(iframe, {
                    autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1, onComplete: () => {
                        this.changeVideoDetails();
                    }
                },)
                .to(iframe, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.5')
        },
        nextProject() {
            if (this.navbarSub === 0) {
                this.nextProjectVideo();
            }
        },
        prevProject() {
            if (this.navbarSub === 0) {
                this.prevProjectVideo();
            }
        },
        openSocial(social){
            if(social === 'linktree'){
                window.open("https://linktr.ee/hidden_crackling", "_blank");
            }
        }
    },
    mounted() {


        let h1__info = document.querySelector('.h1__info');
        let visualarts__statement = document.querySelectorAll('.col-4 p');
        let h1__projects = document.querySelector('.h1__projects');
        let btn__prev = document.querySelector('.btn__prev');
        let btn__next = document.querySelector('.btn__next');
        let card = document.querySelector('.visualarts__project-card');

        gsap.timeline()
            .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.1')
            .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(h1__projects, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(btn__prev, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(btn__next, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            .to(card, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
    }
}
</script>

<template>
    <div class="visualarts">
        <b-row>
            <b-col cols="4">
                <h1 class="h1__info">{{navbarNames.information}}</h1>
                <p class="visualarts__statement" style="white-space: break-spaces;">{{navbarNames.statement}}</p>
                <p class="cv__title sociallink" @click="openSocial('linktree')">
                    <img src="../assets/img/svg/tabler--brand-linktree.svg" alt="icon_linktree"/>
                    Hidden Crackling
                </p>
            </b-col>
            <b-col class="position-relative">
                <h1 class="h1__projects">{{navbarNames.records}}</h1>

                <div class="btn__prev" @click="prevProject">
                    <svg viewBox="0 0 15.8 18.16">
                        <polygon class="cls-1"
                                 points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
                    </svg>
                    <p class="prev">{{ navbarNames.prev }}</p>
                </div>
                <div class="btn__next" @click="nextProject">
                    <p class="next">{{navbarNames.next}}</p>
                    <svg viewBox="0 0 15.8 18.16">
                        <polygon class="cls-1"
                                 points="0 9.24 14.73 0 15.8 1.69 3.81 9.21 15.78 16.45 14.75 18.16 0 9.24"/>
                    </svg>

                </div>
                <div class="visualarts__project">
                    <div class="visualarts__project-card">
<!--                        <div class="cardimgbox">-->
<!--                            <div class="visualarts__project-card-img">-->
<!--                                &lt;!&ndash;                            <img src="../assets/img/09_bardo/11.webp" />&ndash;&gt;-->
<!--                            </div>-->
<!--                        </div>-->

                        <iframe src="https://www.youtube.com/embed/KbGpMiDsvq4?si=Bh1K65_XwIAcB-QT" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>

<!--                        <p class="title">Totoya Klub - Tilos Rádió (2023. 05. 02.)</p>-->
<!--                        <p class="open" v-if="currentProject === 0">-->
<!--                            <vue-plyr  >-->
<!--                                <audio controls crossorigin playsinline>-->
<!--                                    <source src="../assets/img/content/music/totoya03.mp3" type="audio/mp3">-->
<!--                                </audio>-->
<!--                            </vue-plyr>-->
<!--                        </p>-->
<!--                        <p class="open" v-if="currentProject === 1">-->
<!--                            <vue-plyr  >-->
<!--                                <audio controls crossorigin playsinline>-->
<!--                                    <source src="../assets/img/content/music/hidden2.mp3" type="audio/mp3">-->
<!--                                </audio>-->
<!--                            </vue-plyr>-->
<!--                        </p>-->
<!--                        <p class="open" v-if="currentProject === 2">-->
<!--                            <vue-plyr  >-->
<!--                                <audio controls crossorigin playsinline>-->
<!--                                    <source src="../assets/img/content/music/totoya03.mp3" type="audio/mp3">-->
<!--                                </audio>-->
<!--                            </vue-plyr>-->
<!--                        </p>-->
                    </div>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<style scoped>
.cv__title img{
    width: 30px !important;
    visibility: inherit !important;
    margin-right: 3px;
    margin-top: -3px;
}

.cv__title{
    margin-top: 30px;
    color: var(--darkred) !important;
    font-size: 18pt;
}

.sociallink{
    cursor: pointer;
}

.sociallink:hover{
    color: var(--red) !important;
}

@media only screen and (max-width: 1023px) {
    .visualarts {
        position: relative;
        margin-top: 100px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .row{
        position: relative !important;
        height: initial !important;
    }

    .col-4, .col{
        width: 100vw;
        margin: 0 !important;
        padding: 0 !important;
        flex: 0 0 auto;
    }

    .visualarts__statement{
        width: fit-content;
        overflow: hidden;
        padding: 0 !important;
        hyphens: auto;
    }

    .prev, .next{
        font-size: 10pt !important;
    }

    svg{
        width: 10px !important;
    }

    .prev{
        margin-left: 5px !important;
    }

    .next{
        margin-right: 5px !important;
    }

    .btn__prev{
        top: -6px !important;
        right: 100px !important;
    }
    .btn__next{
        top: -7.3px !important;
    }

    .open svg {
        margin-top: -2.7px;
        margin-left: 3px;
    }

    .h1__projects, .col__video iframe, .visualarts__project-card, .btn__prev, .btn__next, .row__cv img{
        visibility: visible !important;
        transform: initial !important;
    }

    .col:last-child{
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px){

}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    h1{
        font-size: 1.2rem;
    }

    .btn__prev, .btn__next{
        top: -16px !important;
        display: block;
    }

    .visualarts__statement{
        max-height: 50vh;
        font-size: 10pt;
        overflow-y: scroll;
    }

    .sociallink{
        font-size: 14pt !important;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 16pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    h1{
        font-size: 2rem;
    }

    .visualarts__statement{
        max-height: 50vh;
        overflow-y: scroll;

    }

    .visualarts__project-card .title {
        bottom: 8px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 15px;
    }
}

@media only screen and (min-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }
    h1{
        font-size: 2.5rem;
    }

    .visualarts__statement{
        max-height: 70vh;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1400px) {
    .visualarts__statement{
        overflow-y: scroll !important;
        max-height: 60vh;
    }

    iframe{
        height: 75% !important;
    }

.cv__title{
    font-size: 14pt !important
}

    .cv__exhibitions{
        max-height: initial !important;
    }

    .row__cv_scroll{
        overflow-x: scroll !important;
    }
}

@media only screen and (min-width: 2560px) {

    .visualarts__statement{
        font-size: 13pt !important;
        padding-right: 80px;
    }
}

@media only screen and (min-width: 3840px) {
    h1{
        font-size: 40pt !important;
    }
    .cardimgbox{
        height: 94% !important;
    }

    .visualarts__statement{
        font-size: 20pt !important;
        padding-right: 120px;
        line-height: 40px;
    }

    .prev, .next, .open{
        font-size: 20pt !important;
    }

    .visualarts__project-card .title{
        font-size: 26pt !important;
        bottom: 5px !important
    }

    .btn__prev, .btn__next{
        top: 0px !important;
        display: block;
    }

    .btn__prev{
        right: 230px !important;
    }
    .cv__title{
        font-size: 24pt !important;
    }

    .cv__entry{
        font-size: 20pt !important;
    }

    .cv__subentry{
        font-size: 16pt !important;
    }

    .row__cv_scroll{
        height: 75vh !important;
    }

    .visualarts__project-card .open{
        bottom: 5px !important;
    }
}
iframe {
    width: 100%;
    height: 92%;
    //visibility: hidden;
}

.open .cls-1 {
    transition: ease all 0.3s;
}

.cv__title img {
    width: 30px !important;
    visibility: inherit !important;
    margin-right: 3px;
    margin-top: -3px;
}

.visualarts__statement {
    font-size: 12pt;
    color: var(--darkred);
    visibility: hidden;
}

.cv__title {
    margin-top: 30px;
    color: black;
    font-size: 18pt;
}

.row_active {
    z-index: 10;
}

.row_inactive {
    z-index: 1;
}

.cv__subentry-job {
    margin-bottom: 0 !important;
}

.h1__info, .cv__entry, .h1__cv, .cv__title, .cv__subentry {
    visibility: hidden;
    transform: translate(-50px, 0);
}

.cv__subentry img {
    visibility: inherit !important;
    width: 20px !important;
    margin-top: -4px;
}

.h1__projects {
    visibility: hidden;
}

.btn__prev {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 7px;
    right: 160px;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__next {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 6px;
    right: 0;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__prev:hover .prev, .btn__next:hover .next {
    color: var(--red)
}

.btn__prev:hover .cls-1, .btn__next:hover .cls-1 {
    fill: var(--red)
}

.btn__next svg, .open svg {
    transform: rotate(180deg);
}

.open svg {
    margin-top: -3.1px;
    margin-left: 10px;
}

.row:nth-child(1) {
}

svg {
    margin-top: -2px;
    width: 15px;
}

.cls-1 {
    fill: black;
}

.next {
    margin-top: 15px;
    margin-right: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.prev {

    margin-top: 14px;
    margin-left: 15px;
    font-size: 13pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.visualarts__project {
//background-color: green; width: 100%; height: 100%;
}

.cv__entry {
    font-size: 15pt;
    color: var(--red);
    margin-bottom: 0;
    margin-left: 40px;
}

.cv__subentry {
    font-size: 12pt;
    color: var(--darkred);
    margin-left: 40px;
}

.cv__subentry-jobtitle {
    font-size: 15pt !important;
    color: black !important;
    margin-top: -3px;
}


.col__video {
    margin-left: 0 !important;
}

.col__video iframe {
    width: 100%;
    height: 95%;
    visibility: hidden;
}

.row {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    height: 75vh;
    position: absolute;
    left: 0;
    top: 0;
}

.col-4 {
//padding: 20px 30px; text-align: justify;
}

h1 {
    margin-bottom: 20px;
}

.col {
//padding-left: 20px; //padding-top: 30px; margin-left: 40px; margin-right: 0 !important;
    padding-right: 0 !important;
//background-color: green;
}

.visualarts__project-card {
    visibility: hidden;
    margin-top: 15px;
//background-color: green; width: 100%; height: 100%;
    position: relative;
}


.cardimgbox {
    height: 88%;
    width: 100%;
    overflow: hidden;
}

.visualarts__project-card .open {
    position: absolute;
    bottom: 15px;
    right: -10px;
    font-size: 13pt;
    max-width: 350px;
    transition: ease all 0.3s;
}

.visualarts__project-card .title {
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 20pt;
    transition: ease all 0.3s;
}

.visualarts__project-card img {
    width: 100%;
}

.row__cv img {
    width: 100%;
    visibility: hidden;
}

h2 {
    margin-top: 10px;
}
</style>