<script>
import {gsap} from "gsap";

export default {
    name: 'ContactView',
    data() {
        return {
            currentProject: 0,
        }
    },
    computed: {
        navbarSub: function () {
            return this.$store.getters.navbarSub;
        },
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;

            if (language === 'hu') {
                returnValue = {
                    contact: 'Kapcsolat',
                    text: 'Amennyiben szeretnéd felvenni velem a kapcsolatot kérlek keress bizalommal az alábbi elérhetőségeimen:'
                }
            } else {
                returnValue = {
                    contact: 'Contact',
                    text: 'If you would like to contact me, please feel free to reach out using the contact details below:'
                }
            }
            return returnValue;
        }
    },
    watch: {
        'navbarSub'(newValue, oldValue) {
            this.currentProject = 0;
            this.hideOldElements(oldValue);
            this.showNewElements(newValue);
        }
    },
    components: {},
    methods: {
        hideOldElements(oldValue) {
            if (oldValue === 0) {
                let h1__info = document.querySelector('.row__cv .h1__cv');
                let visualarts__statement = document.querySelectorAll('.row__cv p');
                let img = document.querySelector('.row__cv .cv__image');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.1}, )
                    .to(visualarts__statement, {autoAlpha: 0, x: -50, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(img, {autoAlpha: 0, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
        },
        showNewElements(newValue) {
            if (newValue === 0) {
                let h1__info = document.querySelector('.row__cv .h1__cv');
                let visualarts__statement = document.querySelectorAll('.row__cv p');
                let img = document.querySelector('.row__cv .cv__image');

                gsap.timeline()
                    .to(h1__info, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.1')
                    .to(visualarts__statement, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.01}, '-=0.15')
                    .to(img, {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '-=0.15')
            }
        },
        openSocial(social){
            if(social === 'instagram'){
                window.open("https://www.instagram.com/lordbokody/", "_blank");
            }
            if(social === 'linkedin'){
                window.open("https://www.linkedin.com/in/akosbokody/", "_blank");
            }
        }
    },
    mounted() {
        this.showNewElements(0);
    }
}
</script>

<template>
    <div class="visualarts">

        <b-row class="row__cv">
            <b-col cols="4" class="position-relative">
                <h1 class="h1__cv">{{navbarNames.contact}}</h1>
                <p class="cv__title">
                    {{navbarNames.text}}
                </p>

                <p class="cv__title">
                    <img src="../assets/img/svg/mail_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_email"/>
                    akos@bokody.hu
                </p>
                <p class="cv__title">
                    <img src="../assets/img/svg/phone_iphone_FILL0_wght400_GRAD0_opsz24.svg" alt="icon_phone"/>
                    +36 20 283 70 41
                </p>
                <p class="cv__title sociallink" @click="openSocial('instagram')" >
                    <img src="../assets/img/svg/instagram.svg" alt="icon_instagram"/>
                    Instagram
                </p>
                <p class="cv__title sociallink" @click="openSocial('linkedin')" >
                    <img src="../assets/img/svg/mdi--linkedin.svg" alt="icon_linkedin"/>
                    LinkedIn
                </p>

            </b-col>
            <b-col>
                <img class="cv__image" alt="img_cv" src="../assets/img/content/contact/received_251768595980965.webp" />
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>

@media only screen and (max-width: 1023px) {
    p{
        font-size: 14pt !important;
    }
    .visualarts {
        position: relative;
        margin-top: 100px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .row{
        position: relative !important;
        height: initial !important;
    }

    .col-4, .col{
        width: 100vw;
        margin: 0 !important;
        padding: 0 !important;
        flex: 0 0 auto;
    }


    svg{
        width: 10px !important;
    }

    .open svg {
        margin-top: -2.7px;
        margin-left: 3px;
    }

    .h1__projects, .col__video iframe, .visualarts__project-card, .btn__prev, .btn__next, .row__cv img{
        visibility: visible !important;
        transform: initial !important;
    }

    .col:last-child{
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px){

}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    h1{
        font-size: 1.2rem;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 16pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }

    h1{
        font-size: 2rem;
    }

    .visualarts__project-card .title {
        bottom: 8px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 15px;
    }
}

@media only screen and (min-width: 1366px) {
    .visualarts {
        position: fixed;
        bottom: 30px;
        right: 40px;
        width: 85vw;
        height: 80vh;
    }
    h1{
        font-size: 2.5rem;
    }

    .visualarts__project-card .title {
        bottom: 15px;
        font-size: 20pt;
    }

    .visualarts__project-card .open {
        bottom: 20px;
    }
}

@media only screen and (min-width: 3840px) {
    h1{
        font-size: 40pt !important;
    }
    .cardimgbox{
        height: 94% !important;
    }

    .visualarts__statement{
        font-size: 20pt !important;
        padding-right: 120px;
        line-height: 40px;
    }

    .prev, .next, .open{
        font-size: 16pt !important;
    }

    .visualarts__project-card .title{
        font-size: 26pt !important;
        bottom: 5px !important
    }

    .btn__prev, .btn__next{
        top: -10px !important;
        display: block;
    }

    .cv__title{
        font-size: 24pt !important;
    }

    .cv__entry{
        font-size: 20pt !important;
    }

    .cv__subentry{
        font-size: 16pt !important;
    }

    .row__cv_scroll{
        height: 75vh !important;
    }
}

.sociallink{
    cursor: pointer;
}

.sociallink:hover{
    color: var(--red)
}

.cv__title img{
    width: 30px !important;
    visibility: inherit !important;
    margin-right: 3px;
    margin-top: -3px;
}
.visualarts__statement {
    font-size: 12pt;
    color: var(--darkred);
    visibility: hidden;
}
.cv__title{
    margin-top: 30px;
    color: var(--darkred);
    font-size: 18pt;
}
.row_active{
    z-index: 10;
}

.row_inactive{
    z-index: 1;
}

.h1__info, .cv__entry, .h1__cv, .cv__title, .cv__subentry {
    visibility: hidden;
    transform: translate(-50px, 0);
}

.h1__projects {
    visibility: hidden;
}

.btn__prev {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 7px;
    right: 160px;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__next {
    visibility: hidden;
    display: flex;
    position: absolute;
    top: 6px;
    right: 0;
    cursor: pointer;
    z-index: 20 !important;
}

.btn__prev:hover .prev, .btn__next:hover .next {
    color: var(--red)
}

.btn__prev:hover .cls-1, .btn__next:hover .cls-1 {
    fill: var(--red)
}

.btn__next svg, .open svg {
    transform: rotate(180deg);
}

.open svg{
    margin-top: -3.1px;
    margin-left: 10px;
}



.row:nth-child(1){
}

svg {
    width: 15px;
}

.cls-1 {
    fill: black;

}

.open .cls-1{
    transition: ease all 0.3s;
}

.next {
    margin-top: 15px;
    margin-right: 15px;
    font-size: 16pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.prev {

    margin-top: 14px;
    margin-left: 15px;
    font-size: 16pt;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.visualarts__project {
//background-color: green; width: 100%;
    height: 100%;
}

.cv__entry {
    font-size: 15pt;
    color: var(--red);
    margin-bottom: 0;
    margin-left: 40px;
}

.cv__subentry {
    font-size: 12pt;
    color: var(--darkred);
    margin-left: 40px;
}


.col__video {
    margin-left: 0 !important;
}

.col__video iframe {
    width: 100%;
    height: 95%;
    visibility: hidden;
}

.row {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    height: 75vh;
    position: absolute;
    left: 0;
    top: 0;
}

.col-4 {
//padding: 20px 30px; text-align: justify;
}

h1 {
    margin-bottom: 20px;
}

.col {
//padding-left: 20px; //padding-top: 30px; margin-left: 40px;
    margin-right: 0 !important;
    padding-right: 0 !important;
//background-color: green;
}

.visualarts__project-card {

    visibility: hidden;
    margin-top: 15px;
//background-color: green; width: 100%;
    height: 100%;
    position: relative;
}

.visualarts__project-card:hover {
    cursor: pointer;
}

.visualarts__project-card:hover .visualarts__project-card-img {
    transform: scale(1.1);
//filter: sepia(100%) hue-rotate(310deg) saturate(400%) contrast(120%);
}

.visualarts__project-card:hover .title, .visualarts__project-card:hover .open{
    color: var(--red);
}

.visualarts__project-card:hover .cls-1{
    fill: var(--red)
}


.visualarts__project-card .open {
    position: absolute;
    bottom: 20px;
    right: 0;
    font-size: 16pt;
    transition: ease all 0.3s;
}

.visualarts__project-card .title {
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 20pt;
    transition: ease all 0.3s;
}

.visualarts__project-card img {
    width: 100%;
}

.row__cv img{
    width: 100%;
    visibility: hidden;
}
h2 {
    margin-top: 10px;
}
</style>