import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
import VuePlyr from '@skjnldsv/vue-plyr'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

// import VueAnalytics from 'vue-analytics';
//
// Vue.use(VueAnalytics, {
//   id: 'G-5L288XKQTM',
// });



import '@skjnldsv/vue-plyr/dist/vue-plyr.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/animations.css'
import '@/assets/css/global.css'
import '@/assets/css/fonts.css'

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VuePlyr, {
  plyr: {}
})

Vue.mixin({
  methods: {
  }
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
