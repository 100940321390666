<script>
import {gsap} from "gsap";

export default {
    name: 'NavBar',
    computed: {
        navbarNames: function () {
            let language = this.$store.getters.language;
            let returnValue;
            if (language === 'hu') {
                returnValue = {
                    visual_painting: 'Festészet',
                    visual_video: 'Mozgókép',
                    visual_exhibitions: 'Kiállítások',
                    visual_cv: 'Önéletrajz',
                    it_portfolio: 'Weboldalak',
                    it_cv: 'Önéletrajz',
                    music_sets: 'DJ szettek',
                    contact: `© Bokody Ákos, ${new Date().getFullYear()}`
                }
            } else {
                returnValue = {
                    visual_painting: 'Painting',
                    visual_video: 'Video',
                    visual_exhibitions: 'Exhibitions',
                    visual_cv: 'CV',
                    it_portfolio: 'Websites',
                    it_cv: 'CV',
                    music_sets: 'DJ sets',
                    contact: `© Ákos Bokody, ${new Date().getFullYear()}`
                }
            }
            return returnValue;
        },
        currentRoute: function () {
            return this.$route.path;
        },
        navbarSub: function () {
            return this.$store.getters.navbarSub;
        },
    },
    watch: {
        'currentRoute'(newValue, oldValue) {
            let newSubIndex;
            let oldSubIndex;
            if (newValue === '/visualarts') {
                newSubIndex = 0;
            }
            if (newValue === '/') {
                newSubIndex = 1;
            }
            if (newValue === '/it') {
                newSubIndex = 2;
            }
            if (newValue === '/music') {
                newSubIndex = 3;
            }
            if (newValue === '/contact') {
                newSubIndex = 4;
            }
            if (oldValue === '/visualarts') {
                oldSubIndex = 0;
            }
            if (oldValue === '/') {
                oldSubIndex = 1;
            }
            if (oldValue === '/it') {
                oldSubIndex = 2;
            }
            if (oldValue === '/music') {
                oldSubIndex = 3;
            }
            if (oldValue === '/contact') {
                oldSubIndex = 4;
            }

            let entries = document.querySelectorAll('.navbarsub__entries');
            if (oldSubIndex === 0 && newSubIndex > 0) {

                for (let i = 0; i < entries.length; i++) {
                    if (i === newSubIndex - 1) {
                        entries[i].style.zIndex = '10';
                    } else {
                        entries[i].style.zIndex = '0';
                    }
                }
                gsap.timeline()
                    .to(entries[newSubIndex - 1].querySelectorAll('p'), {
                        autoAlpha: 1,
                        x: 0,
                        duration: 0.3,
                        stagger: 0.1
                    },)
            }
            if (oldSubIndex > 0 && newSubIndex === 0) {
                gsap.timeline()
                    .to(entries[oldSubIndex - 1].querySelectorAll('p'), {
                        autoAlpha: 0,
                        x: -50,
                        duration: 0.3,
                        stagger: 0.1
                    },)
            }
            if (oldSubIndex > 0 && newSubIndex > 0) {
                for (let i = 0; i < entries.length; i++) {
                    if (i === newSubIndex - 1) {
                        entries[i].style.zIndex = '10';
                    } else {
                        entries[i].style.zIndex = '0';
                    }
                }
                gsap.timeline()
                    .to(entries[oldSubIndex - 1].querySelectorAll('p'), {
                        autoAlpha: 0,
                        x: -50,
                        duration: 0.3,
                        stagger: 0.1
                    },)
                    .to(entries[newSubIndex - 1].querySelectorAll('p'), {
                        autoAlpha: 1,
                        x: 0,
                        duration: 0.3,
                        stagger: 0.1
                    }, '-=0.3')
            }
        },
    },
    methods: {
        changeRoute(route) {
            this.$router.push(route);
        },
        changeSubMenu(index) {
            if (index !== this.navbarSub) {
                this.$store.commit('setNavbarSub', index);
            }

        }
    },
    components: {},
    mounted() {
        let subIndex;
        if (this.$route.path === '/') {
            subIndex = 0;
        }
        if (this.$route.path === '/it') {
            subIndex = 1;
        }
        if (this.$route.path === '/music') {
            subIndex = 2;
        }
        if (this.$route.path === '/contact') {
            subIndex = 3;
        }


        let entries = document.querySelectorAll('.navbarsub__entries');
        for (let i = 0; i < entries.length; i++) {
            if (i === subIndex) {
                entries[i].style.zIndex = '10';
            } else {
                entries[i].style.zIndex = '0';
            }
        }
        gsap.timeline()
            .to(entries[subIndex].querySelectorAll('p'), {autoAlpha: 1, x: 0, duration: 0.3, stagger: 0.1}, '+=0.5')


    }
}
</script>

<template>
    <div class="navbarsub">
        <div class="navbarsub__entries">
            <p @click="changeSubMenu(0)" :class="{navbar__active: navbarSub === 0}">{{
                navbarNames.visual_painting
                }}</p>
            <p @click="changeSubMenu(1)" :class="{navbar__active: navbarSub === 1}">{{ navbarNames.visual_video }}</p>
            <p @click="changeSubMenu(2)" :class="{navbar__active: navbarSub === 2}">{{
                navbarNames.visual_exhibitions
                }}</p>
            <p @click="changeSubMenu(3)" :class="{navbar__active: navbarSub === 3}">{{ navbarNames.visual_cv }}</p>
        </div>
        <div class="navbarsub__entries">
            <p @click="changeSubMenu(0)" :class="{navbar__active: navbarSub === 0}">{{ navbarNames.it_portfolio }}</p>
            <p @click="changeSubMenu(1)" :class="{navbar__active: navbarSub === 1}">{{ navbarNames.it_cv }}</p>
        </div>
        <div class="navbarsub__entries">
            <p @click="changeSubMenu(0)" :class="{navbar__active: navbarSub === 0}">{{ navbarNames.music_sets }}</p>
        </div>
        <div class="navbarsub__entries">
            <p :class="{navbar__active: navbarSub === 0}">{{ navbarNames.contact }}</p>
        </div>
    </div>
</template>

<style scoped>
p {
    transform: translate(-50px, 0);
    visibility: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navbarsub__entries {
    position: fixed;
}

.navbar__active {
    color: var(--red);
    cursor: initial;
}

p:hover {
    color: var(--red);
}

@media only screen and (max-width: 1023px) {
   .navbarsub{
       display: none;
   }
}

@media only screen and (min-width: 769px) and (max-width: 1023px){

}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
    .navbarsub{
        display: block;
    }

    p {
        font-size: 12pt;
    }

    .navbarsub__entries {
        bottom: 15px;
        left: 20px;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1366px) {
    .navbarsub{
        display: block;
    }

    p {
        font-size: 14pt;
    }

    .navbarsub__entries {
        bottom: 30px;
        left: 40px;
    }
}

@media only screen and (min-width: 1366px)  {
    .navbarsub{
        display: block;
    }

    p {
        font-size: 18pt;
    }

    .navbarsub__entries {
        bottom: 30px;
        left: 40px;
    }

}

@media only screen and (min-width: 3840px) {
    p {
        font-size: 24pt;
    }

    .navbarsub__entries {
        bottom: 50px;
        left: 40px;
    }
}
</style>